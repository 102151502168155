<template>
<b-container fluid>
  <b-row class="text-center mb-4" align-h="center" v-if="loading">
    <b-col cols="1">
      <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
    </b-col>
  </b-row>
  <b-row class="row text-white bg-kpmg-blue py-4">
    <b-col align-self="center" cols="2" class=" cols text-center" align-v="center">
      <h1 justify="center" align="center" class="kstyle title justify-content-md-center">Documentation</h1>
    </b-col>
    <b-col cols="8" align-self="center" align-v="center" class="search justify-content-md-center">
      <b-container>
        <b-row>
          <b-col>
            <b-input-group>
              <b-form-input v-model="filter" placeholder="Type to Search" />
                <b-btn :disabled="!filter" @click="filter = ''">clear</b-btn>
            </b-input-group>
          </b-col>
        </b-row>
      </b-container>
    </b-col>
  </b-row>
  <b-row class="mb-4" v-if="!loading && permissions.add">
    <b-col>
      <b-button class="mr-2 my-4" @click="showOrder = !showOrder" variant="outline-primary">show order</b-button>
      <b-button class="mr-2 my-4" variant="outline-primary" :to="{ name: 'FormNew', params: { model: 'doc' }}">new</b-button>
    </b-col>
  </b-row>
  <b-row class="mb-4" v-if="!loading">
    <b-col>
      <h3 class="mb-4">Contents</h3>
      <div class="mb-3" v-for="row in rowsShow" :key="row.id">
        <line-item
          :item="row"
          :username="user.username"
          :showOrder="showOrder"
        />
      </div>
    </b-col>
    <b-col>
      <h3 class="mb-4">Index</h3>
      <div class="mb-3" v-for="keyword in keywords" :key="keyword">
        <b-link @click="filter = keyword" v-if="filter === keyword && filter !== ''"><strong>{{keyword}}</strong></b-link><b-button v-if="filter === keyword && filter !== ''" class="mx-2" @click="filter = ''" variant="primary" size="sm">clear</b-button>
        <b-link @click="filter = keyword" v-if="filter !== keyword">{{keyword}}</b-link>
      </div>
    </b-col>
  </b-row>
</b-container>
</template>

<script>
import _ from 'lodash'
import ac from '@/libs/accesscontrol'

import LineItem from '@/views/docs/DocSimple.vue'

export default {
  name: 'Reportingframework',
  components: {
    LineItem
  },
  computed: {
    rowsShow: function () {
      let rows = []
      rows = this.rows
      if (this.filter.length > 0) {
        rows = rows.filter(x => {
          const term = x.id + ' ' + x.name + ' ' + x.keywords
          return term.toUpperCase().match(this.filter.toUpperCase())
        })
      }
      return rows
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    document.title = "KPMG Regulatory Horizon: Documentation"
    this.$stat.log({ page: 'docs', action: 'open docs' })
    this.permissions.add = ac.can(this.user.acgroups).createAny('doc').granted
    this.load()
  },
  data () {
    const data = {
      filter: '',
      keywords: [],
      loading: true,
      permissions: {
        add: false,
        read: false
      },
      rows: [],
      showOrder: false
    }
    return data
  },
  methods: {
    load: async function () {
      this.loading = true
      try {
        const rows = await this.$Amplify.API.get('cosmos', '/docs/all')
        let keywords = []
        rows.forEach(x => {
          const words = x.keywords.split(';')
          words.forEach(y => {
            keywords.push(_.trim(y))
          })
        })
        keywords = _.uniq(keywords)
        keywords = _.sortBy(keywords, x => x)
        this.keywords = keywords
        this.rows = rows
        this.loading = false
      } catch (e) {
        this.$logger.warn('load ERROR: ', e)
      }
    }
  }
}
</script>

<style>
</style>
